import { NgModule } from '@angular/core';
import { NgxFreshChatService } from './ngx-freshchat-lib.service';


@NgModule({
  imports: [],
  providers: [ NgxFreshChatService ],
  declarations: [],
  exports: []
})
export class NgxFreshChatModule { }
